export enum APIEndpoints {
    User = "/users",
    //
    Topic = "/topics",
    Calendar = "/calendars",
    Session = "/sessions",
    TopicQuestions = "/questions",
    //
    Onboard = "/commercialrequests",
    //
    Subscription = "/subscriptions",
    Expert = "/specialists"
}